const $ = require("jquery");
class Nav{
  constructor(){
    this.menu = $('#js-navigation-menu');
    this.menuToggle = $('#js-mobile-menu').unbind();
    this.menuLink = $('.nav-link a');
  }
  start(){
    

  this.menu.removeClass("show");

  this.menuToggle.on('click', (e) => {
    e.preventDefault();
    this.menu.slideToggle(() => {
      if(this.menu.is(':hidden')) {
        console.log(this.menu.is(':hidden'))
        this.menu.removeAttr('style');
      }
    });
  });

   this.menuLink.on('click', (e) => {
    e.preventDefault();

     if(!window.matchMedia("(min-width: 400)").matches && this.menu.is(':visible') )  {

        this.menu.delay( 500 ).removeAttr('style');

    } 

    });

  }

}

  

export { Nav }

