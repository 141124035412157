 import { Nav }  from './modules/nav';
import AOS from "AOS";
  


 // console.log(NavI.start);
function juizScrollTo(element){			
	$(element).click(function(){
		var goscroll = false;
		var the_hash = $(this).attr("href");
		var regex = new RegExp("\#(.*)","gi");
		var the_element = '';
 
		if(the_hash.match("\#(.+)")) {
			the_hash = the_hash.replace(regex,"$1");
 
			if($("#"+the_hash).length>0) {
				the_element = "#" + the_hash;
				goscroll = true;
			}
			else if($("a[name=" + the_hash + "]").length>0) {
				the_element = "a[name=" + the_hash + "]";
				goscroll = true;
			}
			if(goscroll) {
				$('html, body').animate({
					scrollTop:$(the_element).offset().top
				}, 'slow');
				return false;
			}
		}
	});				

};

function scrollActive(){

	let sectionActive = 0;
	let sections = document.querySelectorAll(".section");
	let links = document.querySelectorAll(".nav-link a");
	let heightPage = document.body.scrollHeight;
	let offset = [0];
	let lastLinkIndex = 0;
	let StartTop = window.scrollY;
	



	for (let i = 0; i <= sections.length - 1; i++) {


     offset.push(sections[i].offsetTop - 25);
     if (i == sections.length - 1 ) {
     	offset.push(heightPage)
     }
	}
	// console.log(offset, 'off')
	// console.log(sections, 'sections')


  for (let i = 0; i <= offset.length - 1; i++) {
// console.log(StartTop, 'i start')
	if (StartTop >= offset[i] && StartTop < offset[i + 1]) {


		  	links[offset.indexOf(offset[i])].classList.add('active');


	}

}



window.addEventListener("scroll", function(event) {
     let top = this.scrollY;
    // console.log(top)



for (let i = 0; i <= offset.length - 1; i++) {

	if (top >= offset[i] && top < offset[i + 1]) {

		if (lastLinkIndex  !==  i) {
			links[lastLinkIndex].classList.remove('active');

			lastLinkIndex = offset.indexOf(offset[i]);
		  	// console.log(offset.indexOf(offset[i]) , 'current');
		  	
		  	links[offset.indexOf(offset[i])].classList.add('active');
		}


			

		  	

	}

}

}, false);





	window.onresize = function() {
  
	scrollActive();
};


}


$(document).ready(function() {
	var NavI = new Nav();
	NavI.start();

	$('.my-slider').unslider({
		infinite: true,
		arrows: {
	//  Unslider default behaviour
	prev: '<a class="unslider-arrow prev"><</a>',
	next: '<a class="unslider-arrow next">></a>',
	}

	});

	juizScrollTo('a[href^="#"]');
	scrollActive();


	 AOS.init({
      disable: 'mobile'
    });

});



